.Explore {
    .MainTitle {
        &-title {
            text-align: left;
        }
        
        &-text {
            width: 35%;
            text-align: left;
            margin: 0;
        }
    }
}