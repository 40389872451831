.Contact {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: -100px;
    left: 0;
    right: 0;
    margin: auto;
}
