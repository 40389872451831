.ContactCard {
    width: 30%;
    padding: 30px 50px;
    border-radius: 20px;
    box-shadow: 5px 10px 25px 0px rgba(0,0,0,0.45);
    background-color: #fff;

    svg {
        width: 20px;
        height: 20px;
        padding: 30px;
        border-radius: 50%;
        background-color: #F0F0F0;
    }

    &-title {
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        color: #2A2A2A;
        margin: 15px 0;
    }

    &-text {
        font-size: 15px;
        font-weight: 400;
        line-height: 22.5px;
        color: #22B3C1;
    }
}