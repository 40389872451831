.Prices {
    margin-bottom: 100px !important;
    border-bottom: 1px solid #eee;

    &-cards {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .swiper {
        width: 100%;
        height: 100%;

    }

    .swiper-wrapper {
        // width: 100%;
        width: 100% !important;
    }
    
    .swiper-slide {
        // background-position: center;
        // background-size: cover;
        width: 25% !important;
        height: 570px !important;
        margin-right: 170px !important;
    }
    
    .swiper-slide img {
        // display: block;
        // width: 100%;
    }
}
