.HeroSection {
    text-align: center;
    // background-image: url("../../assets/images/bgImg1.jpg");
    background-position: center;
    object-fit: contain;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    // height: 100vh;
    margin-bottom: 60px;

    &-inner {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
    }

    &-title {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: #FFFFFF;
        padding-top: 150px;
    }
    
    &-cityName {
        font-weight: 700;
        font-size: 50px;
        line-height: 60px;
        text-align: center;
        color: #FFFFFF;
        margin: 30px 0;
    }

    .carousel {
        z-index: 1;
        // position: absolute;
        // height: 100%;
        width: 100%;

        $carousel-transition-duration: 2s !default;
        
            .carousel-control-prev, .carousel-control-next {
                display: none;
            }
        
        
        &-item {
            // margin: auto;

            img {
                width: 100%;
                height: 100vh;
                object-fit: cover !important;
            }
        }

        .carousel-caption {
            position: absolute;
            // right: 15%;
            top: 80px;
            // left: 15%;
            
            // height: 100vh;
            // object-fit: cover;
            // padding-top: 1.25rem;
            // padding-bottom: 1.25rem;
            color: #fff;
            text-align: center;
        }
    }
}