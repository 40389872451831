.Header {
    background-color: #22b3c1;
    box-sizing: content-box;
    position: fixed;
    width: 100%;
    z-index: 2;

    &-inner {
        display: flex;
        justify-content: space-between;
        align-items: center;

        img {
            width: 170px;
        }
    }

    &-links {
        display: flex;
    }

    ul {
        margin: 0;
    }

    li {
        padding: 30px 0;
    }

    &-link {
        font-size: 15px;
        font-weight: 400;
        letter-spacing: 0.5px;
        line-height: 22.5px;
        color: #ffffff;
        padding: 30px 30px;
        position: relative;
        transition: all ease 0.4s;
        box-sizing: border-box;

        &::before {
            content: "";
            position: absolute;
            width: 0;
            height: 1px;
            background-color: #fff;
            top: 25px;
            left: 30px;
            transition: all ease 0.4s;
        }

        &:hover::before {
            width: 30%;
        }
    }

    &-langs {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &-lang {
        font-size: 15px;
        font-weight: 400;
        letter-spacing: 0.5px;
        line-height: 22.5px;
        color: #ffffff;
        padding: 20px 15px;
    }

    button {
        border: none;
        outline: none;
        background-color: transparent;
    }
}
